import Vue from "vue";
import axios from "axios";
// axios.defaults.baseURL = "http://192.168.1.138:8091/";

const server = axios.create();
server.defaults.baseURL = "https://zhly.jlxrmtzx.com:3012/";

const server2 = axios.create();
//f服务器
server2.defaults.baseURL = "https://zhly.jlxrmtzx.com:3013/gkptapi";
//本地
// server2.defaults.baseURL = "http://192.168.1.44:8080";


Vue.prototype.$axios = server;
Vue.prototype.$axios2 = server2;
