import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "Home",
    component: Home,
  },
  {
    path: "/openPathDetail/:id",
    name: "openPathDetail",
    component: () => import("../views/OpenPathDetail.vue"),
  },
  {
    path: "/openPathForm",
    name: "openPathForm",
    component: () => import("../views/openPathForm.vue"),
    meta: {
      title: '旅行团接待登记'
    }
  },
  {
    path: "/openPathForm2",
    name: "openPathForm2",
    component: () => import("../views/openPathForm2.vue"),
    meta: {
      title: '游客入园登记'
    }
  },
  {
    path: "/code/:id/:classify",
    name: "code",
    component: () => import("../views/code.vue"),
    meta: {
      title: '二维码'
    }
  },
];

const router = new VueRouter({
  mode: "hash",
  base: process.env.BASE_URL,
  routes,
});

export default router;
