import Vue from "vue";

import App from "./App.vue";
import router from "./router";
import store from "./store";
import VueAMap from "vue-amap";
import "@/assets/css/global.css";
import "./utils/axios.js";
import Notify from "vant/lib/notify";
import {
  Area,
  CellGroup,
  DatetimePicker,
  Radio,
  RadioGroup,
  Stepper,
  Swipe,
  SwipeItem,
} from "vant";
import { DropdownMenu, DropdownItem } from "vant";
import { Form, Field, Button, Popup, Picker } from "vant";

import "vant/lib/index.css";

import { Progress } from "element-ui";
import "element-ui/lib/theme-chalk/index.css";
Vue.use(Progress);
Vue.use(Notify);
Vue.use(Swipe);
Vue.use(SwipeItem);
Vue.use(DropdownMenu);
Vue.use(DropdownItem);
Vue.use(Form);
Vue.use(Field);
Vue.use(Button);
Vue.use(Popup);
Vue.use(Picker);
Vue.use(DatetimePicker);
Vue.use(Stepper);
Vue.use(CellGroup);
Vue.use(Area);
Vue.use(Radio);
Vue.use(RadioGroup);

Vue.use(VueAMap);
import QRCode from 'qrcodejs2';
Vue.prototype.$qrCode = QRCode;
VueAMap.initAMapApiLoader({
  key: "eb2e8a0f04420db858f8a6355a6c185c",
  plugin: [
    "AMap.Scale",
    "AMap.OverView",
    "AMap.ToolBar",
    "AMap.MapType",
    "AMap.Geolocation",
    "AMap.Driving",
    "AMap.Walking",
  ],
  v: "1.4.4",
  uiVersion: "1.0.11",
});
router.beforeEach((to, form, next) => {
  window.document.title = to.meta.title ? to.meta.title : '导览'
  next()
})

Vue.config.productionTip = false;

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
